export const englishText = {
  title: "Frontend Developer",
  name: "Alan Rich",
  description: `
      I'm a software engineer with over five years of experience developing React applications for Fortune 500 
      corporations and national security contractors. A sample of my work can be found at 
      <a href="https://www.decouvrirpatrimoine.fr" target="_blank" rel="noopener noreferrer">www.DecouvrirPatrimoine.fr</a>, 
      a data-driven dashboard application that aggregates and visualizes government data on French heritage. In my previous 
      career, I was an organic chemist developing enantiomer-specific organocatalytic reactions. I have been living in 
      France since 2020, working remotely for American corporations. I am currently seeking my first role with a French 
      organization, motivated by a desire to more fully integrate into society. I am excited to contribute my unique 
      experiences and skills, whilst embracing new lessons that will support my ongoing professional development.`,
};

export const frenchText = {
  title: "Développeur Frontend",
  name: "Alan Rich",
  description: `
 Je suis ingénieur logiciel avec plus de cinq ans d’expérience dans le développement d’applications React pour des entreprises 
 du Fortune 500 et des contractants en sécurité nationale. Un exemple de mon travail est disponible sur <a href="https://www.decouvrirpatrimoine.fr" 
 target="_blank" rel="noopener noreferrer">www.DecouvrirPatrimoine.fr</a>, une application de tableau de bord axée sur les données qui agrège 
 et visualise les données gouvernementales sur le patrimoine français. Dans ma carrière précédente, j’étais chimiste organicien, spécialisé dans 
 le développement de réactions organocatalytiques spécifiques à l'énantiomère. Je vis en France depuis 2020 et travaille à distance pour des 
 entreprises américaines. Je recherche actuellement mon premier poste au sein d’une organisation française, motivé par le désir de m’intégrer 
 plus pleinement dans la société. Je suis enthousiaste à l’idée de contribuer avec mes expériences et compétences uniques, tout en étant prêt à 
 apprendre de nouvelles leçons pour soutenir mon développement professionnel continu.`,
};

export const workSampleEn = {
  title: "Work Sample",
  description:
    "Please have patience with the inital load time, I'm using eco dynos on Heroku. ",
};

export const workSampleFr = {
  title: "Exemple de Projet",
  description:
    "Veuillez patienter pendant le chargement initial, Heroku utilise des eco dynos.",
};
